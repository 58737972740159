import { openDB } from "idb";

let openedDb;

async function open() {
    if (openedDb) {
        return openedDb;
    }

    openedDb = await openDB("awenko", 2, {
        upgrade: function upgrade(oldDb) {
            if (!oldDb.objectStoreNames.contains("blobs")) {
                oldDb.createObjectStore("blobs");
            }

            if (!oldDb.objectStoreNames.contains("files")) {
                oldDb.createObjectStore("files", { keyPath: "filename" });
            }

            if (!oldDb.objectStoreNames.contains("users")) {
                oldDb.createObjectStore("users", { keyPath: "userId" });
            }

            if (!oldDb.objectStoreNames.contains("userEvents")) {
                oldDb.createObjectStore("userEvents", { keyPath: ["userId", "version"] });
            }

            if (!oldDb.objectStoreNames.contains("userCommands")) {
                oldDb
                    .createObjectStore("userCommands", { autoIncrement: true })
                    .createIndex("uixCommandId", "commandId", { unique: true });
            }

            if (!oldDb.objectStoreNames.contains("organisationUnits")) {
                oldDb.createObjectStore("organisationUnits", { keyPath: "organisationUnitId" });
            }

            if (!oldDb.objectStoreNames.contains("deletedOrganisationUnits")) {
                oldDb.createObjectStore("deletedOrganisationUnits", { keyPath: "organisationUnitId" });
            }

            if (!oldDb.objectStoreNames.contains("organisationUnitEvents")) {
                oldDb.createObjectStore("organisationUnitEvents", { keyPath: "version" });
            }

            if (!oldDb.objectStoreNames.contains("organisationUnitCommands")) {
                oldDb
                    .createObjectStore("organisationUnitCommands", { autoIncrement: true })
                    .createIndex("uixCommandId", "commandId", { unique: true });
            }

            if (!oldDb.objectStoreNames.contains("plans")) {
                oldDb.createObjectStore("plans", { keyPath: "planId" });
            }

            if (!oldDb.objectStoreNames.contains("planEvents")) {
                oldDb.createObjectStore("planEvents", { keyPath: ["planId", "version"] });
            }

            if (!oldDb.objectStoreNames.contains("planCommands")) {
                oldDb
                    .createObjectStore("planCommands", { autoIncrement: true })
                    .createIndex("uixCommandId", "commandId", { unique: true });
            }

            if (!oldDb.objectStoreNames.contains("audits")) {
                oldDb.createObjectStore("audits", { keyPath: "auditId" });
            }

            if (!oldDb.objectStoreNames.contains("auditEvents")) {
                oldDb.createObjectStore("auditEvents", { keyPath: ["auditId", "version"] });
            }

            if (!oldDb.objectStoreNames.contains("auditCommands")) {
                oldDb
                    .createObjectStore("auditCommands", { autoIncrement: true })
                    .createIndex("uixCommandId", "commandId", { unique: true });
            }

            if (!oldDb.objectStoreNames.contains("measures")) {
                oldDb.createObjectStore("measures", { keyPath: "measureId" });
            }

            if (!oldDb.objectStoreNames.contains("measureEvents")) {
                oldDb.createObjectStore("measureEvents", { keyPath: ["measureId", "version"] });
            }

            if (!oldDb.objectStoreNames.contains("measureCommands")) {
                oldDb
                    .createObjectStore("measureCommands", { autoIncrement: true })
                    .createIndex("uixCommandId", "commandId", { unique: true });
            }

            if (!oldDb.objectStoreNames.contains("tenant")) {
                oldDb.createObjectStore("tenant", { keyPath: "tenantId" });
            }

            if (!oldDb.objectStoreNames.contains("tenantEvents")) {
                oldDb.createObjectStore("tenantEvents", { keyPath: ["tenantId", "version"] });
            }

            if (!oldDb.objectStoreNames.contains("tenantCommands")) {
                oldDb
                    .createObjectStore("tenantCommands", { autoIncrement: true })
                    .createIndex("uixCommandId", "commandId", { unique: true });
            }

            if (!oldDb.objectStoreNames.contains("checkpointGroups")) {
                oldDb.createObjectStore("checkpointGroups", { keyPath: "checkpointGroupId" });
            }

            if (!oldDb.objectStoreNames.contains("checkpoints")) {
                oldDb.createObjectStore("checkpoints", { keyPath: "checkpointId" });
            }

            if (!oldDb.objectStoreNames.contains("checkpointDataEvents")) {
                oldDb.createObjectStore("checkpointDataEvents", { keyPath: "version" });
            }

            if (!oldDb.objectStoreNames.contains("checkpointDataCommands")) {
                oldDb
                    .createObjectStore("checkpointDataCommands", { autoIncrement: true })
                    .createIndex("uixCommandId", "commandId", { unique: true });
            }

            if (!oldDb.objectStoreNames.contains("valueClasses")) {
                oldDb.createObjectStore("valueClasses", { keyPath: "valueClassId" });
            }

            if (!oldDb.objectStoreNames.contains("propertiesEvents")) {
                oldDb.createObjectStore("propertiesEvents", { keyPath: "version" });
            }

            if (!oldDb.objectStoreNames.contains("propertiesCommands")) {
                oldDb
                    .createObjectStore("propertiesCommands", { autoIncrement: true })
                    .createIndex("uixCommandId", "commandId", { unique: true });
            }

            if (!oldDb.objectStoreNames.contains("metadata")) {
                oldDb.createObjectStore("metadata");
            }
        },
    });

    return openedDb;
}

async function loadSession() {
    const db = await open();
    const tx = db.transaction(["metadata"], "readonly");
    const metadataStore = tx.objectStore("metadata");

    const session = await metadataStore.get("session");

    await tx.done;

    return session;
}

async function saveSession(session) {
    const db = await open();
    const tx = db.transaction(["metadata"], "readwrite");
    const metadataStore = tx.objectStore("metadata");

    await metadataStore.put(session, "session");
    await tx.done;
}

async function deleteSession() {
    const db = await open();

    const tx = db.transaction(["metadata", "blobs", "files", "users", "userEvents", "userCommands", "organisationUnits", "deletedOrganisationUnits",
        "organisationUnitEvents", "organisationUnitCommands", "plans", "planEvents", "planCommands", "audits", "auditEvents", "auditCommands", "measures",
        "measureEvents", "measureCommands", "tenant", "tenantEvents", "tenantCommands", "checkpointGroups", "checkpoints", "checkpointDataEvents",
        "checkpointDataCommands", "valueClasses", "propertiesEvents", "propertiesCommands"], "readwrite");

    const metadataStore = tx.objectStore("metadata");
    const baseUri = await metadataStore.get("baseUri");
    await metadataStore.clear();
    metadataStore.put(baseUri, "baseUri");

    const blobStore = tx.objectStore("blobs");
    await blobStore.clear();

    const filesStore = tx.objectStore("files");
    await filesStore.clear();

    const usersStore = tx.objectStore("users");
    await usersStore.clear();

    const userEventsStore = tx.objectStore("userEvents");
    await userEventsStore.clear();

    const userCommandsStore = tx.objectStore("userCommands");
    await userCommandsStore.clear();

    const organisationUnitsStore = tx.objectStore("organisationUnits");
    await organisationUnitsStore.clear();

    const deletedOrganisationUnitsStore = tx.objectStore("deletedOrganisationUnits");
    await deletedOrganisationUnitsStore.clear();

    const organisationUnitEventsStore = tx.objectStore("organisationUnitEvents");
    await organisationUnitEventsStore.clear();

    const organisationUnitCommandsStore = tx.objectStore("organisationUnitCommands");
    await organisationUnitCommandsStore.clear();

    const plansStore = tx.objectStore("plans");
    await plansStore.clear();

    const planEventsStore = tx.objectStore("planEvents");
    await planEventsStore.clear();

    const planCommandsStore = tx.objectStore("planCommands");
    await planCommandsStore.clear();

    const auditsStore = tx.objectStore("audits");
    await auditsStore.clear();

    const auditEventsStore = tx.objectStore("auditEvents");
    await auditEventsStore.clear();

    const auditCommandsStore = tx.objectStore("auditCommands");
    await auditCommandsStore.clear();

    const measuresStore = tx.objectStore("measures");
    await measuresStore.clear();

    const measureEventsStore = tx.objectStore("measureEvents");
    await measureEventsStore.clear();

    const measureCommandsStore = tx.objectStore("measureCommands");
    await measureCommandsStore.clear();

    const tenantStore = tx.objectStore("tenant");
    await tenantStore.clear();

    const tenantEventsStore = tx.objectStore("tenantEvents");
    await tenantEventsStore.clear();

    const tenantCommandsStore = tx.objectStore("tenantCommands");
    await tenantCommandsStore.clear();

    const checkpointGroupsStore = tx.objectStore("checkpointGroups");
    await checkpointGroupsStore.clear();

    const checkpointsStore = tx.objectStore("checkpoints");
    await checkpointsStore.clear();

    const checkpointDataEventsStore = tx.objectStore("checkpointDataEvents");
    await checkpointDataEventsStore.clear();

    const checkpointDataCommandsStore = tx.objectStore("checkpointDataCommands");
    await checkpointDataCommandsStore.clear();

    const valueClassesStore = tx.objectStore("valueClasses");
    await valueClassesStore.clear();

    const propertiesEventsStore = tx.objectStore("propertiesEvents");
    await propertiesEventsStore.clear();

    const propertiesCommandsStore = tx.objectStore("propertiesCommands");
    await propertiesCommandsStore.clear();
    await tx.done;
}

async function loadBaseUri() {
    const db = await open();
    const tx = db.transaction(["metadata"], "readonly");
    const metadataStore = tx.objectStore("metadata");

    const baseUri = await metadataStore.get("baseUri");

    await tx.done;

    return baseUri;
}

async function saveBaseUri(baseUri) {
    const db = await open();
    const tx = db.transaction(["metadata"], "readwrite");
    const metadataStore = tx.objectStore("metadata");

    await metadataStore.put(baseUri, "baseUri");
    await tx.done;
}

async function loadBlob(filename) {
    const db = await open();
    const tx = db.transaction(["blobs"], "readonly");
    const blobStore = tx.objectStore("blobs");

    const blob = await blobStore.get(filename);

    await tx.done;

    return blob;
}

async function saveBlob(filename, blob) {
    const db = await open();
    const tx = db.transaction(["blobs"], "readwrite");
    const blobStore = tx.objectStore("blobs");

    await blobStore.put(blob, filename);
    await tx.done;
}

async function loadFiles() {
    const db = await open();
    const tx = db.transaction(["files"], "readonly");

    const files = await tx.objectStore("files").getAll();

    await tx.done;

    return files;
}

async function saveFile(file) {
    const db = await open();
    const tx = db.transaction(["files"], "readwrite");
    const fileStore = tx.objectStore("files");

    await fileStore.put(file);

    await tx.done;
}

async function saveFileAndBlob(file, blob) {
    const db = await open();
    const tx = db.transaction(["files", "blobs"], "readwrite");
    const fileStore = tx.objectStore("files");
    const blobStore = tx.objectStore("blobs");

    await Promise.all([
        fileStore.put(file),
        blobStore.put(blob, file.filename),
    ]);

    await tx.done;
}

async function saveFiles(files) {
    const db = await open();
    const tx = db.transaction(["files"], "readwrite");
    const fileStore = tx.objectStore("files");
    const fileCount = files ? files.length : 0;

    await fileStore.clear();

    if (fileCount) {
        const promises = new Array(fileCount);

        for (let i = 0; i < fileCount; i += 1) {
            promises.push(fileStore.add(files[i]));
        }

        await Promise.all(promises);
    }

    await tx.done;
}

async function saveFileModifications(modifications) {
    const changedCount = modifications && modifications.changed ? modifications.changed.length : 0;
    const deletedCount = modifications && modifications.deleted ? modifications.deleted.length : 0;

    if (!changedCount && !deletedCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["files"], "readwrite");
    const fileStore = tx.objectStore("files");
    const promises = new Array(changedCount + deletedCount);

    for (let i = 0; i < changedCount; i += 1) {
        promises.push(fileStore.put(modifications.changed[i]));
    }

    for (let i = 0; i < deletedCount; i += 1) {
        promises.push(fileStore.delete(modifications.deleted[i]));
    }

    await Promise.all(promises);
    await tx.done;
}

async function deleteFile(filename) {
    const db = await open();
    const tx = db.transaction(["files", "blobs"], "readwrite");
    const fileStore = tx.objectStore("files");
    const blobStore = tx.objectStore("blobs");

    await Promise.all([
        fileStore.delete(filename),
        blobStore.delete(filename),
    ]);

    await tx.done;
}

async function loadUserData() {
    const db = await open();
    const tx = db.transaction(["users", "userEvents", "userCommands", "metadata"], "readonly");

    const result = await Promise.all([
        tx.objectStore("users").getAll(),
        tx.objectStore("metadata").get("userLastEventId"),
        tx.objectStore("userEvents").getAll(),
        tx.objectStore("userCommands").getAll(),
    ]);

    await tx.done;

    return {
        users: {
            users: result[0],
            lastEventId: result[1] || null,
        },
        events: result[2],
        commands: result[3],
    };
}

async function saveUsers(users) {
    const db = await open();
    const tx = db.transaction(["users", "userEvents", "userCommands", "metadata"], "readwrite");
    const userStore = tx.objectStore("users");
    const eventStore = tx.objectStore("userEvents");
    const commandStore = tx.objectStore("userCommands");
    const metadataStore = tx.objectStore("metadata");
    const userCount = users && users.users ? users.users.length : 0;
    const promises = new Array(userCount + 1);

    await Promise.all([
        userStore.clear(),
        eventStore.clear(),
        commandStore.clear(),
    ]);

    for (let i = 0; i < userCount; i += 1) {
        promises.push(userStore.add(users.users[i]));
    }

    promises.push(metadataStore.put(users.lastEventId, "userLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function saveUserModifications(modifications) {
    const changedUserCount = modifications && modifications.changed ? modifications.changed.length : 0;
    const deletedUserCount = modifications && modifications.deleted ? modifications.deleted.length : 0;

    if (!changedUserCount && !deletedUserCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["users", "userEvents", "userCommands", "metadata"], "readwrite");
    const userStore = tx.objectStore("users");
    const eventStore = tx.objectStore("userEvents");
    const commandStore = tx.objectStore("userCommands");
    const metadataStore = tx.objectStore("metadata");
    const promises = new Array(changedUserCount + deletedUserCount + 3);

    for (let i = 0; i < changedUserCount; i += 1) {
        promises.push(userStore.put(modifications.changed[i]));
    }

    for (let i = 0; i < deletedUserCount; i += 1) {
        promises.push(userStore.delete(modifications.deleted[i]));
    }

    promises.push(eventStore.clear());
    promises.push(commandStore.clear());
    promises.push(metadataStore.put(modifications.lastEventId, "userLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function saveUserCommandsAndEvents(commands, events) {
    const commandCount = commands ? commands.length : 0;
    const eventCount = events ? events.length : 0;

    if (!commandCount && !eventCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["userEvents", "userCommands"], "readwrite");
    const eventStore = tx.objectStore("userEvents");
    const commandStore = tx.objectStore("userCommands");
    const promises = new Array(eventCount + commandCount);

    for (let i = 0; i < eventCount; i += 1) {
        promises.push(eventStore.put(events[i]));
    }

    for (let i = 0; i < commandCount; i += 1) {
        promises.push(commandStore.put(commands[i]));
    }

    await Promise.all(promises);
    await tx.done;
}

async function removeUserCommand(commandId) {
    const db = await open();
    const tx = db.transaction("userCommands", "readwrite");
    const commandStore = tx.objectStore("userCommands");
    const key = await commandStore.index("uixCommandId").getKey(commandId);

    if (key) {
        await commandStore.delete(key);
    }

    await tx.done;
}

async function removeUserCommandAndEvent(commandId, userId, eventVersion) {
    const db = await open();
    const tx = db.transaction(["userCommands", "userEvents"], "readwrite");
    const commandStore = tx.objectStore("userCommands");
    const eventStore = tx.objectStore("userEvents");
    const commandKey = await commandStore.index("uixCommandId").getKey(commandId);

    if (commandKey) {
        await commandStore.delete(commandKey);
        await eventStore.delete([userId, eventVersion]);
    }

    await tx.done;
}

async function loadOrganisationUnitData() {
    const db = await open();
    const tx = db.transaction([
        "organisationUnits",
        "deletedOrganisationUnits",
        "organisationUnitEvents",
        "organisationUnitCommands",
        "metadata",
    ], "readonly");

    const result = await Promise.all([
        tx.objectStore("organisationUnits").getAll(),
        tx.objectStore("deletedOrganisationUnits").getAll(),
        tx.objectStore("metadata").get("organisationUnitFirstId"),
        tx.objectStore("metadata").get("organisationUnitLastId"),
        tx.objectStore("metadata").get("organisationUnitVersion"),
        tx.objectStore("metadata").get("organisationUnitLastEventId"),
        tx.objectStore("organisationUnitEvents").getAll(),
        tx.objectStore("organisationUnitCommands").getAll(),
    ]);

    await tx.done;

    return {
        organisationUnits: {
            hierarchy: {
                organisationUnits: result[0],
                deletedOrganisationUnits: result[1],
                firstId: result[2] || null,
                lastId: result[3] || null,
            },
            version: result[4] || -1,
            lastEventId: result[5] || null,
        },
        events: result[6],
        commands: result[7],
    };
}

async function saveOrganisationUnits(organisationUnits) {
    const db = await open();
    const tx = db.transaction([
        "organisationUnits",
        "deletedOrganisationUnits",
        "organisationUnitEvents",
        "organisationUnitCommands",
        "metadata",
    ], "readwrite");
    const organisationUnitStore = tx.objectStore("organisationUnits");
    const deletedOrganisationUnitStore = tx.objectStore("deletedOrganisationUnits");
    const eventStore = tx.objectStore("organisationUnitEvents");
    const commandStore = tx.objectStore("organisationUnitCommands");
    const metadataStore = tx.objectStore("metadata");
    const organisationUnitCount = organisationUnits.hierarchy.organisationUnits ? organisationUnits.hierarchy.organisationUnits.length : 0;
    const deletedOrganisationUnitCount = organisationUnits.hierarchy.deletedOrganisationUnits ? organisationUnits.hierarchy.deletedOrganisationUnits.length : 0;
    const promises = new Array(organisationUnitCount + 4);

    await Promise.all([
        organisationUnitStore.clear(),
        deletedOrganisationUnitStore.clear(),
        eventStore.clear(),
        commandStore.clear(),
    ]);

    for (let i = 0; i < organisationUnitCount; i += 1) {
        promises.push(organisationUnitStore.add(organisationUnits.hierarchy.organisationUnits[i]));
    }

    for (let i = 0; i < deletedOrganisationUnitCount; i += 1) {
        promises.push(deletedOrganisationUnitStore.add(organisationUnits.hierarchy.deletedOrganisationUnits[i]));
    }

    promises.push(metadataStore.put(organisationUnits.hierarchy.firstId, "organisationUnitFirstId"));
    promises.push(metadataStore.put(organisationUnits.hierarchy.lastId, "organisationUnitLastId"));
    promises.push(metadataStore.put(organisationUnits.version, "organisationUnitVersion"));
    promises.push(metadataStore.put(organisationUnits.lastEventId, "organisationUnitLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function saveOrganisationUnitModifications(modifications) {
    if (!modifications) {
        return;
    }

    const changedOrganisationUnitCount = modifications.changedOrganisationUnits ? modifications.changedOrganisationUnits.length : 0;
    const deletedOrganisationUnitCount = modifications.deletedOrganisationUnits ? modifications.deletedOrganisationUnits.length : 0;

    if (!changedOrganisationUnitCount && !deletedOrganisationUnitCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction([
        "organisationUnits",
        "deletedOrganisationUnits",
        "organisationUnitEvents",
        "organisationUnitCommands",
        "metadata",
    ], "readwrite");
    const organisationUnitStore = tx.objectStore("organisationUnits");
    const deletedOrganisationUnitStore = tx.objectStore("deletedOrganisationUnits");
    const eventStore = tx.objectStore("organisationUnitEvents");
    const commandStore = tx.objectStore("organisationUnitCommands");
    const metadataStore = tx.objectStore("metadata");
    const promises = new Array(changedOrganisationUnitCount + deletedOrganisationUnitCount + 6);

    for (let i = 0; i < changedOrganisationUnitCount; i += 1) {
        promises.push(organisationUnitStore.put(modifications.changedOrganisationUnits[i]));
    }

    for (let i = 0; i < deletedOrganisationUnitCount; i += 1) {
        promises.push(organisationUnitStore.delete(modifications.deletedOrganisationUnits[i].organisationUnitId));
        promises.push(deletedOrganisationUnitStore.add(modifications.deletedOrganisationUnits[i]));
    }

    promises.push(eventStore.clear());
    promises.push(commandStore.clear());

    promises.push(metadataStore.put(modifications.firstId, "organisationUnitFirstId"));
    promises.push(metadataStore.put(modifications.lastId, "organisationUnitLastId"));
    promises.push(metadataStore.put(modifications.version, "organisationUnitVersion"));
    promises.push(metadataStore.put(modifications.lastEventId, "organisationUnitLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function saveOrganisationUnitCommandsAndEvents(commands, events) {
    const commandCount = commands ? commands.length : 0;
    const eventCount = events ? events.length : 0;

    if (!commandCount && !eventCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["organisationUnitEvents", "organisationUnitCommands"], "readwrite");
    const eventStore = tx.objectStore("organisationUnitEvents");
    const commandStore = tx.objectStore("organisationUnitCommands");
    const promises = new Array(eventCount + commandCount);

    for (let i = 0; i < eventCount; i += 1) {
        promises.push(eventStore.put(events[i]));
    }

    for (let i = 0; i < commandCount; i += 1) {
        promises.push(commandStore.put(commands[i]));
    }

    await Promise.all(promises);
    await tx.done;
}

async function removeOrganisationUnitCommand(commandId) {
    const db = await open();
    const tx = db.transaction("organisationUnitCommands", "readwrite");
    const commandStore = tx.objectStore("organisationUnitCommands");
    const key = await commandStore.index("uixCommandId").getKey(commandId);

    if (key) {
        await commandStore.delete(key);
    }

    await tx.done;
}

async function removeOrganisationUnitCommandAndEvent(commandId, eventVersion) {
    const db = await open();
    const tx = db.transaction(["organisationUnitCommands", "organisationUnitEvents"], "readwrite");
    const commandStore = tx.objectStore("organisationUnitCommands");
    const eventStore = tx.objectStore("organisationUnitEvents");
    const commandKey = await commandStore.index("uixCommandId").getKey(commandId);

    if (commandKey) {
        await commandStore.delete(commandKey);
        await eventStore.delete(eventVersion);
    }

    await tx.done;
}

async function loadPlanData() {
    const db = await open();
    const tx = db.transaction(["plans", "planEvents", "planCommands", "metadata"], "readonly");

    const result = await Promise.all([
        tx.objectStore("plans").getAll(),
        tx.objectStore("metadata").get("planLastEventId"),
        tx.objectStore("planEvents").getAll(),
        tx.objectStore("planCommands").getAll(),
    ]);

    await tx.done;

    return {
        plans: {
            plans: result[0],
            lastEventId: result[1] || null,
        },
        events: result[2],
        commands: result[3],
    };
}

async function savePlans(plans) {
    const db = await open();
    const tx = db.transaction(["plans", "planEvents", "planCommands", "metadata"], "readwrite");
    const planStore = tx.objectStore("plans");
    const eventStore = tx.objectStore("planEvents");
    const commandStore = tx.objectStore("planCommands");
    const metadataStore = tx.objectStore("metadata");
    const planCount = plans.plans ? plans.plans.length : 0;
    const promises = new Array(planCount + 1);

    await Promise.all([
        planStore.clear(),
        eventStore.clear(),
        commandStore.clear(),
    ]);

    for (let i = 0; i < planCount; i += 1) {
        promises.push(planStore.add(plans.plans[i]));
    }

    promises.push(metadataStore.put(plans.lastEventId, "planLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function savePlanModifications(modifications) {
    if (!modifications) {
        return;
    }

    const changedPlanCount = modifications.changed ? modifications.changed.length : 0;
    const deletedPlanCount = modifications.deleted ? modifications.deleted.length : 0;

    if (!changedPlanCount && !deletedPlanCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["plans", "planEvents", "planCommands", "metadata"], "readwrite");
    const planStore = tx.objectStore("plans");
    const eventStore = tx.objectStore("planEvents");
    const commandStore = tx.objectStore("planCommands");
    const metadataStore = tx.objectStore("metadata");
    const promises = new Array(changedPlanCount + deletedPlanCount + 3);

    for (let i = 0; i < changedPlanCount; i += 1) {
        promises.push(planStore.put(modifications.changed[i]));
    }

    for (let i = 0; i < deletedPlanCount; i += 1) {
        promises.push(planStore.delete(modifications.deleted[i]));
    }

    promises.push(eventStore.clear());
    promises.push(commandStore.clear());
    promises.push(metadataStore.put(modifications.lastEventId, "planLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function savePlanCommandsAndEvents(commands, events) {
    const commandCount = commands ? commands.length : 0;
    const eventCount = events ? events.length : 0;

    if (!commandCount && !eventCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["planEvents", "planCommands"], "readwrite");
    const eventStore = tx.objectStore("planEvents");
    const commandStore = tx.objectStore("planCommands");
    const promises = new Array(eventCount + commandCount);

    for (let i = 0; i < eventCount; i += 1) {
        promises.push(eventStore.put(events[i]));
    }

    for (let i = 0; i < commandCount; i += 1) {
        promises.push(commandStore.put(commands[i]));
    }

    await Promise.all(promises);
    await tx.done;
}

async function removePlanCommand(commandId) {
    const db = await open();
    const tx = db.transaction("planCommands", "readwrite");
    const commandStore = tx.objectStore("planCommands");
    const key = await commandStore.index("uixCommandId").getKey(commandId);

    if (key) {
        await commandStore.delete(key);
    }

    await tx.done;
}

async function removePlanCommandAndEvent(commandId, planId, eventVersion) {
    const db = await open();
    const tx = db.transaction(["planCommands", "planEvents"], "readwrite");
    const commandStore = tx.objectStore("planCommands");
    const eventStore = tx.objectStore("planEvents");
    const commandKey = await commandStore.index("uixCommandId").getKey(commandId);

    if (commandKey) {
        await commandStore.delete(commandKey);
        await eventStore.delete([planId, eventVersion]);
    }

    await tx.done;
}

async function loadAuditData() {
    const db = await open();
    const tx = db.transaction(["audits", "auditEvents", "auditCommands", "metadata"], "readonly");

    const result = await Promise.all([
        tx.objectStore("audits").getAll(),
        tx.objectStore("metadata").get("auditLastEventId"),
        tx.objectStore("auditEvents").getAll(),
        tx.objectStore("auditCommands").getAll(),
    ]);

    await tx.done;

    return {
        audits: {
            audits: result[0],
            lastEventId: result[1] || null,
        },
        events: result[2],
        commands: result[3],
    };
}

async function saveAudits(audits) {
    const db = await open();
    const tx = db.transaction(["audits", "auditEvents", "auditCommands", "metadata"], "readwrite");
    const auditStore = tx.objectStore("audits");
    const eventStore = tx.objectStore("auditEvents");
    const commandStore = tx.objectStore("auditCommands");
    const metadataStore = tx.objectStore("metadata");
    const auditCount = audits.audits ? audits.audits.length : 0;
    const promises = new Array(auditCount + 1);

    await Promise.all([
        auditStore.clear(),
        eventStore.clear(),
        commandStore.clear(),
    ]);

    for (let i = 0; i < auditCount; i += 1) {
        promises.push(auditStore.add(audits.audits[i]));
    }

    promises.push(metadataStore.put(audits.lastEventId, "auditLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function saveAuditModifications(modifications) {
    if (!modifications) {
        return;
    }

    const changedAuditCount = modifications.changed ? modifications.changed.length : 0;
    const completedAuditCount = modifications.completed ? modifications.completed.length : 0;
    const deletedAuditCount = modifications.deleted ? modifications.deleted.length : 0;

    if (!changedAuditCount && !completedAuditCount && !deletedAuditCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["audits", "auditEvents", "auditCommands", "metadata"], "readwrite");
    const auditStore = tx.objectStore("audits");
    const eventStore = tx.objectStore("auditEvents");
    const commandStore = tx.objectStore("auditCommands");
    const metadataStore = tx.objectStore("metadata");
    const promises = new Array(changedAuditCount + completedAuditCount + deletedAuditCount + 3);

    for (let i = 0; i < changedAuditCount; i += 1) {
        promises.push(auditStore.put(modifications.changed[i]));
    }

    for (let i = 0; i < completedAuditCount; i += 1) {
        promises.push(auditStore.delete(modifications.completed[i]));
    }

    for (let i = 0; i < deletedAuditCount; i += 1) {
        promises.push(auditStore.delete(modifications.deleted[i]));
    }

    promises.push(eventStore.clear());
    promises.push(commandStore.clear());
    promises.push(metadataStore.put(modifications.lastEventId, "auditLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function saveAuditCommandsAndEvents(commands, events) {
    const commandCount = commands ? commands.length : 0;
    const eventCount = events ? events.length : 0;

    if (!commandCount && !eventCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["auditEvents", "auditCommands"], "readwrite");
    const eventStore = tx.objectStore("auditEvents");
    const commandStore = tx.objectStore("auditCommands");
    const promises = new Array(eventCount + commandCount);

    for (let i = 0; i < eventCount; i += 1) {
        promises.push(eventStore.put(events[i]));
    }

    for (let i = 0; i < commandCount; i += 1) {
        promises.push(commandStore.put(commands[i]));
    }

    await Promise.all(promises);
    await tx.done;
}

async function removeAuditCommand(commandId) {
    const db = await open();
    const tx = db.transaction("auditCommands", "readwrite");
    const commandStore = tx.objectStore("auditCommands");
    const key = await commandStore.index("uixCommandId").getKey(commandId);

    if (key) {
        await commandStore.delete(key);
    }

    await tx.done;
}

async function removeAuditCommandAndEvent(commandId, auditId, eventVersion) {
    const db = await open();
    const tx = db.transaction(["auditCommands", "auditEvents"], "readwrite");
    const commandStore = tx.objectStore("auditCommands");
    const eventStore = tx.objectStore("auditEvents");
    const commandKey = await commandStore.index("uixCommandId").getKey(commandId);

    if (commandKey) {
        await commandStore.delete(commandKey);
        await eventStore.delete([auditId, eventVersion]);
    }

    await tx.done;
}

async function loadMeasureData() {
    const db = await open();
    const tx = db.transaction(["measures", "measureEvents", "measureCommands", "metadata"], "readonly");

    const result = await Promise.all([
        tx.objectStore("measures").getAll(),
        tx.objectStore("metadata").get("measureLastEventId"),
        tx.objectStore("measureEvents").getAll(),
        tx.objectStore("measureCommands").getAll(),
    ]);

    await tx.done;

    return {
        measures: {
            measures: result[0],
            lastEventId: result[1] || null,
        },
        events: result[2],
        commands: result[3],
    };
}

async function saveMeasures(measures) {
    const db = await open();
    const tx = db.transaction(["measures", "measureEvents", "measureCommands", "metadata"], "readwrite");
    const measureStore = tx.objectStore("measures");
    const eventStore = tx.objectStore("measureEvents");
    const commandStore = tx.objectStore("measureCommands");
    const metadataStore = tx.objectStore("metadata");
    const measureCount = measures.measures ? measures.measures.length : 0;
    const promises = new Array(measureCount + 1);

    await Promise.all([
        measureStore.clear(),
        eventStore.clear(),
        commandStore.clear(),
    ]);

    for (let i = 0; i < measureCount; i += 1) {
        promises.push(measureStore.add(measures.measures[i]));
    }

    promises.push(metadataStore.put(measures.lastEventId, "measureLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function saveMeasureModifications(modifications) {
    if (!modifications) {
        return;
    }

    const changedMeasureCount = modifications.changed ? modifications.changed.length : 0;
    const completedMeasureCount = modifications.completed ? modifications.completed.length : 0;
    const deletedMeasureCount = modifications.deleted ? modifications.deleted.length : 0;

    if (!changedMeasureCount && !completedMeasureCount && !deletedMeasureCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["measures", "measureEvents", "measureCommands", "metadata"], "readwrite");
    const measureStore = tx.objectStore("measures");
    const eventStore = tx.objectStore("measureEvents");
    const commandStore = tx.objectStore("measureCommands");
    const metadataStore = tx.objectStore("metadata");
    const promises = new Array(changedMeasureCount + completedMeasureCount + 3);

    for (let i = 0; i < changedMeasureCount; i += 1) {
        promises.push(measureStore.put(modifications.changed[i]));
    }

    for (let i = 0; i < completedMeasureCount; i += 1) {
        promises.push(measureStore.delete(modifications.completed[i]));
    }

    for (let i = 0; i < deletedMeasureCount; i += 1) {
        promises.push(measureStore.delete(modifications.completed[i]));
    }

    promises.push(eventStore.clear());
    promises.push(commandStore.clear());
    promises.push(metadataStore.put(modifications.lastEventId, "measureLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function saveMeasureCommandsAndEvents(commands, events) {
    const commandCount = commands ? commands.length : 0;
    const eventCount = events ? events.length : 0;

    if (!commandCount && !eventCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["measureEvents", "measureCommands"], "readwrite");
    const eventStore = tx.objectStore("measureEvents");
    const commandStore = tx.objectStore("measureCommands");
    const promises = new Array(eventCount + commandCount);

    for (let i = 0; i < eventCount; i += 1) {
        promises.push(eventStore.put(events[i]));
    }

    for (let i = 0; i < commandCount; i += 1) {
        promises.push(commandStore.put(commands[i]));
    }

    await Promise.all(promises);
    await tx.done;
}

async function removeMeasureCommand(commandId) {
    const db = await open();
    const tx = db.transaction("measureCommands", "readwrite");
    const commandStore = tx.objectStore("measureCommands");
    const key = await commandStore.index("uixCommandId").getKey(commandId);

    if (key) {
        await commandStore.delete(key);
    }

    await tx.done;
}

async function removeMeasureCommandAndEvent(commandId, measureId, eventVersion) {
    const db = await open();
    const tx = db.transaction(["measureCommands", "measureEvents"], "readwrite");
    const commandStore = tx.objectStore("measureCommands");
    const eventStore = tx.objectStore("measureEvents");
    const commandKey = await commandStore.index("uixCommandId").getKey(commandId);

    if (commandKey) {
        await commandStore.delete(commandKey);
        await eventStore.delete([measureId, eventVersion]);
    }

    await tx.done;
}

async function loadTenantData() {
    const db = await open();
    const tx = db.transaction(["tenant", "tenantEvents", "tenantCommands"], "readonly");

    const result = await Promise.all([
        tx.objectStore("tenant").getAll(),
        tx.objectStore("tenantEvents").getAll(),
        tx.objectStore("tenantCommands").getAll(),
    ]);

    await tx.done;

    return {
        tenant: result[0][0],
        events: result[1],
        commands: result[2],
    };
}

async function saveTenant(tenant) {
    const db = await open();
    const tx = db.transaction(["tenant", "tenantEvents", "tenantCommands"], "readwrite");
    const tenantStore = tx.objectStore("tenant");
    const eventStore = tx.objectStore("tenantEvents");
    const commandStore = tx.objectStore("tenantCommands");
    const tenantCount = tenant ? 1 : 0;
    const promises = new Array(tenantCount + 1);

    await Promise.all([
        tenantStore.clear(),
        eventStore.clear(),
        commandStore.clear(),
    ]);

    for (let i = 0; i < tenantCount; i += 1) {
        promises.push(tenantStore.add(tenant));
    }

    await Promise.all(promises);
    await tx.done;
}

async function saveTenantModifications(modifications) {
    if (!modifications.changed) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["tenant", "tenantEvents", "tenantCommands"], "readwrite");
    const tenantStore = tx.objectStore("tenant");
    const eventStore = tx.objectStore("tenantEvents");
    const commandStore = tx.objectStore("tenantCommands");
    const promises = new Array(4);

    await Promise.all([
        tenantStore.clear(),
    ]);

    for (let i = 0; i < 1; i += 1) {
        promises.push(tenantStore.add(modifications.changed));
    }

    promises.push(eventStore.clear());
    promises.push(commandStore.clear());

    await Promise.all(promises);
    await tx.done;
}

async function saveTenantCommandsAndEvents(commands, events) {
    const commandCount = commands ? commands.length : 0;
    const eventCount = events ? events.length : 0;

    if (!commandCount && !eventCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["tenantEvents", "tenantCommands"], "readwrite");
    const eventStore = tx.objectStore("tenantEvents");
    const commandStore = tx.objectStore("tenantCommands");
    const promises = new Array(eventCount + commandCount);

    for (let i = 0; i < eventCount; i += 1) {
        promises.push(eventStore.put(events[i]));
    }

    for (let i = 0; i < commandCount; i += 1) {
        promises.push(commandStore.put(commands[i]));
    }

    await Promise.all(promises);
    await tx.done;
}

async function removeTenantCommand(commandId) {
    const db = await open();
    const tx = db.transaction("tenantCommands", "readwrite");
    const commandStore = tx.objectStore("tenantCommands");
    const key = await commandStore.index("uixCommandId").getKey(commandId);

    if (key) {
        await commandStore.delete(key);
    }

    await tx.done;
}

async function removeTenantCommandAndEvent(commandId, tenantId, eventVersion) {
    const db = await open();
    const tx = db.transaction(["tenantCommands", "tenantEvents"], "readwrite");
    const commandStore = tx.objectStore("tenantCommands");
    const eventStore = tx.objectStore("tenantEvents");
    const commandKey = await commandStore.index("uixCommandId").getKey(commandId);

    if (commandKey) {
        await commandStore.delete(commandKey);
        await eventStore.delete([tenantId, eventVersion]);
    }

    await tx.done;
}

async function loadCheckpointData() {
    const db = await open();
    const tx = db.transaction([
        "checkpointGroups",
        "checkpoints",
        "checkpointDataEvents",
        "checkpointDataCommands",
        "metadata",
    ], "readonly");

    const result = await Promise.all([
        tx.objectStore("checkpointGroups").getAll(),
        tx.objectStore("checkpoints").getAll(),
        tx.objectStore("metadata").get("checkpointDataLastEventId"),
        tx.objectStore("checkpointDataEvents").getAll(),
        tx.objectStore("checkpointDataCommands").getAll(),
    ]);

    await tx.done;

    return {
        checkpointData: {
            hierarchy: {
                checkpointGroups: result[0] || -1,
                checkpoints: result[1] || -1,
            },
            lastEventId: result[2] || null,
        },
        events: result[3],
        commands: result[4],
    };
}

async function saveCheckpointData(checkpointData) {
    const db = await open();
    const tx = db.transaction([
        "checkpointGroups",
        "checkpoints",
        "checkpointDataEvents",
        "checkpointDataCommands",
        "metadata",
    ], "readwrite");
    const checkpointGroupStore = tx.objectStore("checkpointGroups");
    const checkpointStore = tx.objectStore("checkpoints");
    const eventStore = tx.objectStore("checkpointDataEvents");
    const commandStore = tx.objectStore("checkpointDataCommands");
    const metadataStore = tx.objectStore("metadata");
    const checkpointGroupCount = checkpointData.hierarchy.checkpointGroups ? checkpointData.hierarchy.checkpointGroups.length : 0;
    const checkpointCount = checkpointData.hierarchy.checkpoints ? checkpointData.hierarchy.checkpoints.length : 0;
    const promises = new Array(checkpointGroupCount + checkpointCount + 1);

    await Promise.all([
        checkpointGroupStore.clear(),
        checkpointStore.clear(),
        eventStore.clear(),
        commandStore.clear(),
    ]);

    for (let i = 0; i < checkpointGroupCount; i += 1) {
        promises.push(checkpointGroupStore.add(checkpointData.hierarchy.checkpointGroups[i]));
    }

    for (let i = 0; i < checkpointCount; i += 1) {
        promises.push(checkpointStore.add(checkpointData.hierarchy.checkpoints[i]));
    }

    promises.push(metadataStore.put(checkpointData.lastEventId, "checkpointDataLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function saveCheckpointDataModifications(modifications) {
    if (!modifications) {
        return;
    }

    const changedCheckpointGroupCount = modifications.changedCheckpointGroups ? modifications.changedCheckpointGroups.length : 0;
    const deletedCheckpointGroupCount = modifications.deletedCheckpointGroups ? modifications.deletedCheckpointGroups.length : 0;
    const changedCheckpointCount = modifications.changedCheckpoints ? modifications.changedCheckpoints.length : 0;
    const deletedCheckpointCount = modifications.deletedCheckpoints ? modifications.deletedCheckpoints.length : 0;

    if (!changedCheckpointGroupCount && !deletedCheckpointGroupCount && !changedCheckpointCount && !deletedCheckpointCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction([
        "checkpointGroups",
        "checkpoints",
        "checkpointDataEvents",
        "checkpointDataCommands",
        "metadata",
    ], "readwrite");
    const checkpointGroupStore = tx.objectStore("checkpointGroups");
    const checkpointStore = tx.objectStore("checkpoints");
    const eventStore = tx.objectStore("checkpointDataEvents");
    const commandStore = tx.objectStore("checkpointDataCommands");
    const metadataStore = tx.objectStore("metadata");
    const promises = new Array(changedCheckpointGroupCount + deletedCheckpointGroupCount + changedCheckpointCount + deletedCheckpointCount + 3);

    for (let i = 0; i < changedCheckpointGroupCount; i += 1) {
        promises.push(checkpointGroupStore.put(modifications.changedCheckpointGroups[i]));
    }

    for (let i = 0; i < deletedCheckpointGroupCount; i += 1) {
        promises.push(checkpointGroupStore.delete(modifications.deletedCheckpointGroups[i]));
    }

    for (let i = 0; i < changedCheckpointCount; i += 1) {
        promises.push(checkpointStore.put(modifications.changedCheckpoints[i]));
    }

    for (let i = 0; i < deletedCheckpointCount; i += 1) {
        promises.push(checkpointStore.delete(modifications.deletedCheckpoints[i]));
    }

    promises.push(eventStore.clear());
    promises.push(commandStore.clear());

    promises.push(metadataStore.put(modifications.lastEventId, "checkpointDataLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function saveCheckpointDataCommandsAndEvents(commands, events) {
    const commandCount = commands ? commands.length : 0;
    const eventCount = events ? events.length : 0;

    if (!commandCount && !eventCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["checkpointDataEvents", "checkpointDataCommands"], "readwrite");
    const eventStore = tx.objectStore("checkpointDataEvents");
    const commandStore = tx.objectStore("checkpointDataCommands");
    const promises = new Array(eventCount + commandCount);

    for (let i = 0; i < eventCount; i += 1) {
        promises.push(eventStore.put(events[i]));
    }

    for (let i = 0; i < commandCount; i += 1) {
        promises.push(commandStore.put(commands[i]));
    }

    await Promise.all(promises);
    await tx.done;
}

async function removeCheckpointDataCommand(commandId) {
    const db = await open();
    const tx = db.transaction("checkpointDataCommands", "readwrite");
    const commandStore = tx.objectStore("checkpointDataCommands");
    const key = await commandStore.index("uixCommandId").getKey(commandId);

    if (key) {
        await commandStore.delete(key);
    }

    await tx.done;
}

async function removeCheckpointDataCommandAndEvent(commandId, eventVersion) {
    const db = await open();
    const tx = db.transaction(["checkpointDataCommands", "checkpointDataEvents"], "readwrite");
    const commandStore = tx.objectStore("checkpointDataCommands");
    const eventStore = tx.objectStore("checkpointDataEvents");
    const commandKey = await commandStore.index("uixCommandId").getKey(commandId);

    if (commandKey) {
        await commandStore.delete(commandKey);
        await eventStore.delete(eventVersion);
    }

    await tx.done;
}

async function loadProperties() {
    const db = await open();
    const tx = db.transaction([
        "valueClasses",
        "propertiesEvents",
        "propertiesCommands",
        "metadata",
    ], "readonly");

    const result = await Promise.all([
        tx.objectStore("valueClasses").getAll(),
        tx.objectStore("metadata").get("propertiesLastEventId"),
        tx.objectStore("propertiesEvents").getAll(),
        tx.objectStore("propertiesCommands").getAll(),
    ]);

    await tx.done;

    return {
        properties: {
            valueClasses: result[0] || -1,
            lastEventId: result[1] || null,
        },
        events: result[2],
        commands: result[3],
    };
}

async function saveProperties(properties) {
    const db = await open();
    const tx = db.transaction([
        "valueClasses",
        "propertiesEvents",
        "propertiesCommands",
        "metadata",
    ], "readwrite");
    const valueClassStore = tx.objectStore("valueClasses");
    const eventStore = tx.objectStore("propertiesEvents");
    const commandStore = tx.objectStore("propertiesCommands");
    const metadataStore = tx.objectStore("metadata");
    const valueClassCount = properties.valueClasses ? properties.valueClasses.length : 0;
    const promises = new Array(valueClassCount + 1);

    await Promise.all([
        valueClassStore.clear(),
        eventStore.clear(),
        commandStore.clear(),
    ]);

    for (let i = 0; i < valueClassCount; i += 1) {
        promises.push(valueClassStore.add(properties.valueClasses[i]));
    }

    promises.push(metadataStore.put(properties.lastEventId, "propertiesLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function savePropertiesModifications(modifications) {
    if (!modifications) {
        return;
    }

    const changedValueClassCount = modifications.changedValueClasses ? modifications.changedValueClasses.length : 0;
    const deletedValueClassCount = modifications.deletedValueClasses ? modifications.deletedValueClasses.length : 0;

    if (!changedValueClassCount && !deletedValueClassCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction([
        "valueClasses",
        "propertiesEvents",
        "propertiesCommands",
        "metadata",
    ], "readwrite");
    const ValueClassStore = tx.objectStore("valueClasses");
    const eventStore = tx.objectStore("propertiesEvents");
    const commandStore = tx.objectStore("propertiesCommands");
    const metadataStore = tx.objectStore("metadata");
    const promises = new Array(changedValueClassCount + deletedValueClassCount + 3);

    for (let i = 0; i < changedValueClassCount; i += 1) {
        promises.push(ValueClassStore.put(modifications.changedValueClassCount[i]));
    }

    for (let i = 0; i < deletedValueClassCount; i += 1) {
        promises.push(ValueClassStore.delete(modifications.deletedValueClassCount[i]));
    }

    promises.push(eventStore.clear());
    promises.push(commandStore.clear());

    promises.push(metadataStore.put(modifications.lastEventId, "propertiesLastEventId"));

    await Promise.all(promises);
    await tx.done;
}

async function savePropertiesCommandsAndEvents(commands, events) {
    const commandCount = commands ? commands.length : 0;
    const eventCount = events ? events.length : 0;

    if (!commandCount && !eventCount) {
        return;
    }

    const db = await open();
    const tx = db.transaction(["propertiesEvents", "propertiesCommands"], "readwrite");
    const eventStore = tx.objectStore("propertiesEvents");
    const commandStore = tx.objectStore("propertiesCommands");
    const promises = new Array(eventCount + commandCount);

    for (let i = 0; i < eventCount; i += 1) {
        promises.push(eventStore.put(events[i]));
    }

    for (let i = 0; i < commandCount; i += 1) {
        promises.push(commandStore.put(commands[i]));
    }

    await Promise.all(promises);
    await tx.done;
}

async function removePropertiesCommand(commandId) {
    const db = await open();
    const tx = db.transaction("propertiesCommands", "readwrite");
    const commandStore = tx.objectStore("propertiesCommands");
    const key = await commandStore.index("uixCommandId").getKey(commandId);

    if (key) {
        await commandStore.delete(key);
    }

    await tx.done;
}

async function removePropertiesCommandAndEvent(commandId, eventVersion) {
    const db = await open();
    const tx = db.transaction(["propertiesCommands", "propertiesEvents"], "readwrite");
    const commandStore = tx.objectStore("propertiesCommands");
    const eventStore = tx.objectStore("propertiesEvents");
    const commandKey = await commandStore.index("uixCommandId").getKey(commandId);

    if (commandKey) {
        await commandStore.delete(commandKey);
        await eventStore.delete(eventVersion);
    }

    await tx.done;
}

export default {
    loadSession: loadSession,
    saveSession: saveSession,
    deleteSession: deleteSession,
    saveBaseUri: saveBaseUri,
    loadBaseUri: loadBaseUri,
    loadBlob: loadBlob,
    saveBlob: saveBlob,
    loadFiles: loadFiles,
    saveFile: saveFile,
    saveFileAndBlob: saveFileAndBlob,
    saveFiles: saveFiles,
    saveFileModifications: saveFileModifications,
    deleteFile: deleteFile,
    loadUserData: loadUserData,
    saveUsers: saveUsers,
    saveUserModifications: saveUserModifications,
    saveUserCommandsAndEvents: saveUserCommandsAndEvents,
    removeUserCommand: removeUserCommand,
    removeUserCommandAndEvent: removeUserCommandAndEvent,
    loadOrganisationUnitData: loadOrganisationUnitData,
    saveOrganisationUnits: saveOrganisationUnits,
    saveOrganisationUnitModifications: saveOrganisationUnitModifications,
    saveOrganisationUnitCommandsAndEvents: saveOrganisationUnitCommandsAndEvents,
    removeOrganisationUnitCommand: removeOrganisationUnitCommand,
    removeOrganisationUnitCommandAndEvent: removeOrganisationUnitCommandAndEvent,
    loadPlanData: loadPlanData,
    savePlans: savePlans,
    savePlanModifications: savePlanModifications,
    savePlanCommandsAndEvents: savePlanCommandsAndEvents,
    removePlanCommand: removePlanCommand,
    removePlanCommandAndEvent: removePlanCommandAndEvent,
    loadAuditData: loadAuditData,
    saveAudits: saveAudits,
    saveAuditModifications: saveAuditModifications,
    saveAuditCommandsAndEvents: saveAuditCommandsAndEvents,
    removeAuditCommand: removeAuditCommand,
    removeAuditCommandAndEvent: removeAuditCommandAndEvent,
    loadMeasureData: loadMeasureData,
    saveMeasures: saveMeasures,
    saveMeasureModifications: saveMeasureModifications,
    saveMeasureCommandsAndEvents: saveMeasureCommandsAndEvents,
    removeMeasureCommand: removeMeasureCommand,
    removeMeasureCommandAndEvent: removeMeasureCommandAndEvent,
    loadTenantData: loadTenantData,
    saveTenant: saveTenant,
    saveTenantModifications: saveTenantModifications,
    saveTenantCommandsAndEvents: saveTenantCommandsAndEvents,
    removeTenantCommand: removeTenantCommand,
    removeTenantCommandAndEvent: removeTenantCommandAndEvent,
    loadCheckpointData: loadCheckpointData,
    saveCheckpointData: saveCheckpointData,
    saveCheckpointDataModifications: saveCheckpointDataModifications,
    saveCheckpointDataCommandsAndEvents: saveCheckpointDataCommandsAndEvents,
    removeCheckpointDataCommand: removeCheckpointDataCommand,
    removeCheckpointDataCommandAndEvent: removeCheckpointDataCommandAndEvent,
    loadProperties: loadProperties,
    saveProperties: saveProperties,
    savePropertiesModifications: savePropertiesModifications,
    savePropertiesCommandsAndEvents: savePropertiesCommandsAndEvents,
    removePropertiesCommand: removePropertiesCommand,
    removePropertiesCommandAndEvent: removePropertiesCommandAndEvent,
};
